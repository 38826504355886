export enum RedirectReason {
  None = "none",
  Follow = "follow",
  AddList = "add_list",
  CreateProject = "create_project",
  Like = "like",
  Order = "order",
  ChangeEmail = "change_email",
  ChangePassword = "change_password",
  ContactMaker = "contact_maker",
}
