import { useCallback } from "react";
import useFirebaseAuth from "./useFirebaseAuth";
import { useAppDispatch } from "@/stores/index";
import { fetchMe } from "@/stores/auth";
import useAnalytics from "./useAnalytics";
import { AuthProvider } from "@/domain/values/AuthProvider";

interface IDPHandlers {
  onUserExists: () => void;
  onUserNotExists: () => void;
}

interface UseGoogleSignInReturn {
  signIn: (handlers: IDPHandlers) => Promise<void>;
}

const useGoogleSignIn = (): UseGoogleSignInReturn => {
  const { event } = useAnalytics();
  const appDispatch = useAppDispatch();
  const { signInWithGoogle } = useFirebaseAuth();

  const signIn = useCallback(
    async ({ onUserExists, onUserNotExists }: IDPHandlers) => {
      const result = await signInWithGoogle();

      if (!result) {
        return;
      }

      const user = await appDispatch(fetchMe())
        .unwrap()
        .catch(() => {
          onUserNotExists();

          return;
        });

      if (user) {
        event("login", { method: AuthProvider.Google });
        onUserExists();
      }
    },
    [appDispatch, signInWithGoogle, event],
  );

  return { signIn };
};

export default useGoogleSignIn;
