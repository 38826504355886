import { fetchMe } from "@/stores/auth";
import useAnalytics from "./useAnalytics";
import { SubmitHandler } from "react-hook-form";
import { useCallback } from "react";
import { useAppDispatch } from "@/stores/index";
import useFirebaseAuth from "./useFirebaseAuth";
import { AuthProvider } from "@/domain/values/AuthProvider";

export type SignInInput = {
  email: string;
  password: string;
};

export type UseSignInResponse = {
  signIn: SubmitHandler<SignInInput>;
};

const useSignIn = (): UseSignInResponse => {
  const appDispatch = useAppDispatch();
  const { event } = useAnalytics();
  const { signInWithEmailAndPassword } = useFirebaseAuth();

  const signIn = useCallback(
    async (data: SignInInput) => {
      await signInWithEmailAndPassword(data.email, data.password);
      await appDispatch(fetchMe());

      event("login", { method: AuthProvider.Password });
    },
    [appDispatch, event, signInWithEmailAndPassword],
  );

  return {
    signIn,
  };
};
export default useSignIn;
