import { FC } from "react";
import Link from "next/link";
import { tv } from "tailwind-variants";
import { TextButtonAlign } from "@/components/TextButton/types/TextButtonAlign";
import { TextButtonSize } from "@/components/TextButton/types/TextButtonSize";

type Props = {
  href: string;
  label: string;
  size?: TextButtonSize;
  align?: TextButtonAlign;
};

const buttonStyle = tv({
  base: "underline",
  variants: {
    size: {
      small: "text-xs",
      medium: "text-sm",
    },
    align: {
      left: "text-left",
      center: "text-center",
      right: "text-right",
    },
  },
});

const TextButton: FC<Props> = ({
  href,
  label,
  size = "medium",
  align = "center",
}): JSX.Element => {
  return (
    <Link className={buttonStyle({ size, align })} href={href}>
      {label}
    </Link>
  );
};
export default TextButton;
